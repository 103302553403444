<template>
  <div id="poistenie" class="sec wf-section">
    <div class="container"><img src="images/shape-1.png" loading="lazy" sizes="(max-width: 479px) 73vw, (max-width: 767px) 57vw, (max-width: 991px) 58vw, 45vw" srcset="images/shape-1-p-500.png 500w, images/shape-1.png 960w" alt="" class="co-img">
      <div class="co-col">
        <h1 class="h2">Hole - in - one</h1>
        <div class="sp"></div>
        <div>Náhrada nákladov na pohostenie spoluhráčov pri údere Hole-in-One na oficiálnom turnaji registrovanom v príslušnej golfovej asociácii</div>
      </div>
    </div>
    <div class="container c-rev">
      <div class="co-col">
        <h1 class="h2">Golfová výbava</h1>
        <div class="sp"></div>
        <div>Náhrada za osobné veci, poškodené alebo zničené počas prepravy, počas hry, následkom živelnej udalosti - alebo aj veci odcudzené z uzamknutého priestoru, či vozidla</div>
      </div><img src="images/shape-2.png" loading="lazy" sizes="(max-width: 479px) 73vw, (max-width: 767px) 57vw, (max-width: 991px) 58vw, 45vw" srcset="images/shape-2-p-500.png 500w, images/shape-2-p-800.png 800w, images/shape-2.png 960w" alt="" class="co-img">
    </div>
    <div class="container"><img src="images/shape-3.png" loading="lazy" sizes="(max-width: 479px) 73vw, (max-width: 767px) 57vw, (max-width: 991px) 58vw, 45vw" srcset="images/shape-3-p-500.png 500w, images/shape-3.png 960w" alt="" class="co-img">
      <div class="co-col">
        <h1 class="h2">Zodpovednosť za škody</h1>
        <div class="sp"></div>
        <div>Náhrada za škody na zdraví alebo na majetku tretích osôb, zapríčinené v súvislosti s hrou na golfovom ihrisku &nbsp;</div>
      </div>
    </div>
    <div class="container c-rev">
      <div class="co-col">
        <h1 class="h2">Zapožičanie golfového vybavenia</h1>
        <div class="sp"></div>
        <div>Náklady na zapožičanie golfovej výbavy, v prípade omeškania batožiny pri leteckej preprave – alebo v prípade poškodenia, či zničenia vecí počas leteckej prepravy naviac - pre veci, ktoré nie je možné zapožičať, náklady na zakúpenie nových osobných vecí, ako napríklad topánky, alebo oblečenie</div>
      </div><img src="images/shape-4.png" loading="lazy" sizes="(max-width: 479px) 73vw, (max-width: 767px) 57vw, (max-width: 991px) 58vw, 45vw" srcset="images/shape-4-p-500.png 500w, images/shape-4.png 960w" alt="" class="co-img">
    </div>
    <div class="container"><img src="images/shape-5.png" loading="lazy" sizes="(max-width: 479px) 73vw, (max-width: 767px) 57vw, (max-width: 991px) 58vw, 45vw" srcset="images/shape-5-p-500.png 500w, images/shape-5.png 960w" alt="" class="co-img">
      <div class="co-col">
        <h1 class="h2">Členské príspevky</h1>
        <div class="sp"></div>
        <div>Náhrada členských príspevkov golfovému klubu, za obdobie počas ktorého sa poistený nemohol zúčastniť hry golf – následkom neočakávaného a nepredvídateľného úrazu&nbsp;</div>
      </div>
    </div>
    <div class="container c-rev">
      <div class="co-col">
        <h1 class="h2">Úrazové poistenie</h1>
        <div class="sp"></div>
        <div>Náhrada za trvalé následky úrazu, ku ktorému došlo počas hry golf – vrátane denného odškodného za pobyt v nemocnici</div>
      </div><img src="images/shape-6.png" loading="lazy" sizes="(max-width: 479px) 73vw, (max-width: 767px) 57vw, (max-width: 991px) 58vw, 45vw" srcset="images/shape-6-p-500.png 500w, images/shape-6.png 960w" alt="" class="co-img">
    </div>
    <div class="container"><img src="images/shape-7.png" loading="lazy" sizes="(max-width: 479px) 73vw, (max-width: 767px) 57vw, (max-width: 991px) 58vw, 45vw" srcset="images/shape-7-p-500.png 500w, images/shape-7.png 960w" alt="" class="co-img">
      <div class="co-col">
        <h1 class="h2">Poškodenie chrupu</h1>
        <div class="sp"></div>
        <div>Náhrada za poškodenie zubov pri úraze počas golfovej hry – čiastočné alebo úplné odlomenie zubu.</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CoverageNew"
}
</script>

<style scoped>

</style>