<template>
<!--  <div class="accordion-item">-->
<!--    <h2 class="accordion-header" :id="id">-->
<!--      <button :class="{'accordion-button': true, 'collapsed': !first}" type="button" data-bs-toggle="collapse" :data-bs-target="'#' + id_c" :aria-expanded="first" :aria-controls="id_c">-->
<!--        {{ title }}-->
<!--      </button>-->
<!--    </h2>-->
<!--    <div :id="id_c" :class="{'accordion-collapse': true, 'collapse': true, 'show': first}" :aria-labelledby="id" :data-bs-parent="'#' + parentId">-->
<!--      <div class="accordion-body" v-html="content"></div>-->
<!--    </div>-->
<!--  </div>-->
  <div class="ml-w">
    <div class="ml-h" @click="open = !open">
      <div class="h4">{{ title }}</div>
      <div :class="{'ico': true, 'ml-a': true, 'ml-a-open': open}"></div>
    </div>
    <div :class="{'ml-tg': true, 'ml-tg-open': open}">
      <div v-html="content"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemPart",
  props: {
    title: String,
    content: String,
    parentId: String,
    first: Boolean
  },
  data: function (){
    return {
      id: null,
      id_c: null,
      open: false
    }
  },
  created() {
    this.id = 'ac_' + Math.round(Math.random() * 100000);
    this.id_c = this.id + '1';
  }
}
</script>

<style scoped>

</style>