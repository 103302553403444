<template>
<!--  <header class="">-->

<!--    <div class="d-flex justify-content-between align-items-center header-co">-->
<!--      <div class="">-->
<!--        <h1>-->
<!--          <router-link to="/"><img src="/img/gp/logo_vw.svg" alt="logo" class="logo" /></router-link>-->
<!--        </h1>-->
<!--      </div>-->
<!--      <div>-->
<!--        <router-link to="/#">Rozsah poistenia</router-link>-->
<!--        <router-link to="/#">Časté otázky</router-link>-->
<!--      </div>-->
<!--      <div class="text-start p-3">-->
<!--        <i class="fa-solid fa-envelope"></i> <a :href="'mailto:' + $t('contact.email')">{{ $t('contact.email') }}</a><br>-->
<!--        <i class="fa-solid fa-phone"></i> <a :href="'tel' + $t('contact.tel')">{{ $t('contact.tel') }}</a>-->
<!--      </div>-->
<!--    </div>-->
<!--  </header>-->
  <div class="header">
    <div class="header-co">
      <div class="header-l">
        <a href="/" aria-current="page" class="logo-lw w-inline-block w--current"><img src="images/logo_vw.svg" loading="lazy" alt="" class="logo"></a>
      </div>
      <div class="header-c">
        <div class="mm">
          <div class="tl-w">
            <a href="/#poistenie" class="tl w-inline-block">
              <div>Rozsah poistenia</div>
            </a>
            <a href="/#faq" class="tl w-inline-block">
              <div>Časté otázky</div>
            </a>
          </div>
        </div>
      </div>
      <div class="header-r">
        <div class="header-i">
          <div class="rws">
            <div class="ico mr"></div>
            <div>{{ $t('contact.email') }}<br></div>
          </div>
          <div class="rws">
            <div class="ico mr"></div>
            <div>{{ $t('contact.tel') }}<br></div>
          </div>
        </div>
<!--        <a href="#" class="ham w-inline-block">-->
<!--          <div class="hm hm1"></div>-->
<!--          <div class="hm hm2"></div>-->
<!--          <div class="hm hm3"></div>-->
<!--        </a>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderPart",
  props: {
    product: String
  }

}
</script>

<style scoped>
/*header{*/
/*  position: absolute;*/
/*  padding: 20px;*/
/*  z-index: 20;*/
/*  left: 20PX;*/
/*  right: 20PX;*/
/*  box-sizing: border-box;*/
/*}*/
/*  .header-co {*/
/*    width: 100%;*/
/*    border-bottom: 1px solid #00b14e;*/
/*    justify-content: space-between;*/
/*    align-items: center;*/
/*    margin-left: auto;*/
/*    margin-right: auto;*/
/*    padding: 20px 4px;*/
/*    display: flex;*/
/*    position: relative;*/
/*    color: white;*/
/*  }*/
/*  .logo{*/
/*    width: 140px;*/
/*  }*/

/*  h1 a{*/
/*    text-decoration: none;*/
/*  }*/

/*  a{*/
/*    text-decoration: none;*/
/*    color: white;*/
/*  }*/
</style>