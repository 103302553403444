<template>
  <div class="accordion" id="parent">
      <ItemPart :content="content" :first="index === 0" :title="title" parent-id="parent" v-for="({ title, content}, index) in data" :key="title"></ItemPart>
  </div>

</template>
<script>

//import ItemPart from "@/components/accordion/ItemPart";
import ItemPart from "@/components/accordion/ItemPart";
import axios from "axios";
import common from "@/config/common";
export default {
  components: {ItemPart},
  computed: {
    code(){
      return this.$product + '_' + this.$i18n.locale;
    }
  },
  data: function (){
      return {
        data: {}
      }
  },
  created() {
    var self = this;
    axios.get(common.API_BASE_URL + this.$product +'/'+ this.$i18n.locale +'/questions') // 'getCityByPostcode/' + this.formData.postcode
        .then(response => {
          self.data = response.data;
        })
        .catch(error => {
          console.log(error);
          //alert('fetchError' + error);
        })
  },
}
</script>


<style>
.ml-h{
  cursor: pointer;
}
 .mm-open {
   margin-right: 0px;
 }
.hm {
  transition: 0.2s;
}
.hm.hm1 {
  -webkit-transform-origin: 10% 10%;
}
.hm.hm1.hm-open {
  -webkit-transform: rotate(45deg);
}
.hm.hm3 {
  -webkit-transform-origin: 10% 10%;
}
.hm.hm3.hm-open {
  -webkit-transform: rotate(-45deg);
}
.hm.hm2.hm-open {
  opacity: 0.0;
}
.ml-tg {
  display: none;
}
.ml-tg-open {
  display: block;
}
.ml-a {
  transition: 0.2s;
}
.ml-a-open {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}
</style>