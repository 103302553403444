<template>
  <div class="hero-w">
    <div class="hero wf-section">
      <div class="container">
        <div class="hh-co">
          <h1 class="hh1 mb">Oslávte Vašu výhru s naším golfovým poistením</h1>
          <div class="sp"></div>
          <h2 class="hh2">Najoblúbeneišie golfové poistenie už od roku 2002 prináša istotu počas hry na Slovensku aj na cestách za golfom po celom svete.</h2>
        </div>
      </div>
      <div class="hero-ac">
        <a href="/conditions" class="btna w-inline-block">
          <div class="mr">Mám záujem</div>
          <div class="ico ico-r"></div>
        </a>
      </div>
    </div>
  </div>
<!--  <div class="hero-w">-->
<!--    <div class="hero has-content" style="background-image: url('/img/gp/hero-6-p-1080.jpg')">-->
<!--      <div class="container">-->
<!--        <h2 class="mt-4">{{$t("home.coverage_title")}}</h2>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="hero top" style="background-image: url('/img/gp/golf_hero_1920.jpg')">-->

<!--  </div>-->
<!--  <div class="container">-->
<!--    <h2 class="mt-5 mb-5">{{$t("home.coverage_title")}}</h2>-->
<!--  </div>-->
  <CoverageNew></CoverageNew>
  <div id="faq" class="sec wf-section">
    <div class="container">
      <div class="col-13">
        <div class="stck">
          <h2 class="h2 fc-1">Otázky a odpovede</h2>
          <div class="sp"></div>
        </div>
      </div>
      <div class="col-23">
        <FAQPart />
<!--        <div>-->
<!--          <div class="ml-w">-->
<!--            <div class="ml-h">-->
<!--              <div class="h4">Kde platí golfové poistenie?</div>-->
<!--              <div class="ico ml-a"></div>-->
<!--            </div>-->
<!--            <div class="ml-tg">-->
<!--              <div>Poistenie je platné na území celého sveta, vrátane Slovenskej republiky.</div>-->
<!--            </div>-->
<!--          </div>-->


<!--        </div>-->
      </div>
    </div>
  </div>

  <div class="accordion" id="parent">
    <ItemPart :content="content" :first="index === 0" :title="title" parent-id="parent" v-for="({ title, content}, index) in data" :key="title"></ItemPart>
  </div>


<!--  <div class="container mt-5">-->

<!--    <div class="row d-flex justify-content-between align-items-stretch">-->
<!--      <div class="col-md-6">-->

<!--        <CoverageTable></CoverageTable>-->
<!--      </div>-->
<!--      <div class="col-md-6 text-center d-flex flex-md-row align-items-center  justify-content-center">-->
<!--&lt;!&ndash;        <div class="price-wrap d-flex flex-column justify-content-end">&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="price">&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="price-title">&ndash;&gt;-->
<!--&lt;!&ndash;              {{$t('home.price_title')}}&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;            {{$t('home.price')}}&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--        <div class="text-start" v-html="$t('off_text')">-->

<!--        </div>-->
<!--&lt;!&ndash;        <router-link to="/order" class="btn btn-primary shadow-lg btn-lg">{{$t('home.cta')}}</router-link>&ndash;&gt;-->

<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="hero mt-5" style="background-image: url('/img/gp/hero_2.png')">-->
<!--  </div>-->
<!--  <div class="container">-->
<!--    <h3 class="mt-5">{{ $t("home.faq_title") }}</h3>-->

<!--    <div class="mb-4 mt-4">-->
<!--      <FAQPart />-->
<!--    </div>-->
<!--  </div>-->


<!--  <div class="home">-->
<!--    <img alt="Vue logo" src="../assets/logo.png">-->
<!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
<!--  </div>-->

</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
//import HeaderPart from "@/components/parts/HeaderPart";
//import FAQPart from "@/components/accordion/FAQPart";
//import CoverageTable from "@/components/parts/CoverageTable";
import CoverageNew from "@/components/parts/CoverageNew";
import FAQPart from "@/components/accordion/FAQPart";

export default {
  name: 'HomeView',
  data: function (){
    return {

    }
  },
  components: {
    FAQPart,
    CoverageNew,
    // CoverageTable,
    //FAQPart
  }
}
</script>
<style>
img{
  max-width: 100%;

}
strong{
  color: var(--main-color);
}


/*.hero.has-content:after {*/
/*  content: '';*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  display: block;*/
/*  position: absolute;*/
/*  opacity: 1;*/
/*  z-index: 1;*/
/*  background-image: linear-gradient(to top, rgba(0,0,0,0.7), rgba(0,0,0,0.1) 50%, rgba(0,0,0,0.0) 70%);*/
/*}*/
/*.hero .container{*/
/*  position: relative;*/
/*  z-index: 2;*/
/*}*/
/*.hero h2{*/
/*  font-size: 2.5rem;*/
/*  margin-top: 320px;*/
/*  padding-bottom: 15px;*/
/*  color: white;*/
/*}*/



.covering{
  font-size: 0.9em;

}
.covering td{
  padding: 5px;
}
.table td:nth-child(2){
  text-align: right;
}

.price-wrap{
  margin-right: 100px;
  /*flex: 1;*/
}
.price{
  text-align: center;
  font-size: 3em;
  color: var(--main-color);
  font-weight: bold;

}
.price-title{
  font-weight: normal;
  font-size: 0.5em;
  margin-bottom: -10px;
}
</style>
