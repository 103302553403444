export default {
  "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Golfové poistenie"])},
  "off_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do novej golfovej sezóny pre Vás pripravujeme vylepšenú verziu poistenia.<br>V prípade Vášho záujmu nám pošlite Váš kontakt na <a href='mailto:info", "@", "golfinsurance.sk'>info", "@", "golfinsurance.sk</a><br>– budeme Vás informovať akonáhle bude k dispozícii."])},
  "contact": {
    "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0905 128 526"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["info", "@", "golfinsurance.sk"])}
  },
  "home": {
    "coverage_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oslávte Vašu výhru s naším golfovým poistením"])},
    "what_is_covered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["čo je kryté"])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dojednať online"])},
    "price_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ročné poistné"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["99 €"])},
    "faq_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["otázky a odpovede"])}
  },
  "test": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mám záujem o nasledovný rozsah poistenia"])},
    "invalid": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poistenie nespĺňa Vaše požiadavky"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["= prosím kontaktujte naše Klientske centrum, kde Vám poradíme vhodný produkt presne pre Vás"])}
    }
  },
  "people": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahlásenie spolucestujúcich osôb"])}
  },
  "application": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Údaje žiadosti"])}
  },
  "order": {
    "variants": {
      "basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BASIC"])},
      "extended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EXTRA"])}
    },
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odoslať"])},
    "person": {
      "name": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["meno"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ján"])}
      },
      "surname": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["priezvisko"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novák"])}
      },
      "birthday_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dátum narodenia"])},
      "email": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-mail"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["priklad", "@", "gmail.com"])}
      },
      "tel": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["telefón"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+421 000 000 000"])}
      },
      "trip_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trvanie cesty od"])},
      "trip_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trvanie cesty do"])}
    },
    "addPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ pridať ďalšiu osobu"])},
    "address": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adresa trvalého pobytu"])},
      "street": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ulica"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Štefánikova"])}
      },
      "street_number": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["číslo domu"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["201"])}
      },
      "town": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mesto"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bratislava"])}
      },
      "zip": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PSČ"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["941 02"])}
      }
    },
    "national_id": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rodné číslo"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["napr. 2206109334"])}
    },
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["štátna príslušnosť"])},
    "id_card": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["číslo občianskeho preukazu"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S000002"])}
    },
    "action_code": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KOD akcie"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "insurance_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["začiatok platnosti poistenia"])},
    "discount_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zľavový kód"])},
    "consent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "datepicker": {
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zvoliť"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zrušiť"])}
  },
  "navigation": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["naspäť"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pokračovať"])}
  },
  "gdpr": {
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na vami zadanú mailovú adresu Vám posielame potvrdenie o uzatvorení poistenia, poistné podmienky a všetky informačné povinnosti a prosíme Vás o ich preštudovanie. Úhradou poistného zároveň prehlasujete, že  ste boli úplne, zrozumiteľne a v dostatočnom časovom predstihu oboznámený s ich znením a boli Vám vysvetlené všetky ustanovenia v nich obsiahnuté.\n"])},
    "communication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Súhlasím s elektronickou komunikáciou, informácie súvisiace s poskytovaním finančných služieb mi budú zaslané na mailovú adresu, ktorú som zadal. Potvrdzujem, že k tejto schránke elektronickej pošty mám pravidelný prístup."])}
  },
  "header": {
    
  },
  "validation": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pole je povinné"])},
    "national_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rodné číslo nie je validne alebo vek je vyšší ako 80 rokov"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pole musi obsahovať 5 číslic"])},
    "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefónne číslo zadávajte vo formáte: +421 000 000 000"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte korektný email"])}
  },
  "payment": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platba"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaplatiť online kartou"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ďakujeme za vykonanie platby"])},
    "already_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objednávka už bola zaplatená"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platba zlyhala"])},
    "ordered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ďakujeme za vytvorenie objednávky. Platbu možete vykonať kartou alebo prevodom podla pokynov na maili."])}
  },
  "files": {
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informácie o poistení"])},
    "law": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povinné informácie"])},
    "reporting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hlásenie škody"])}
  },
  "footer": {
    "all_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informácie o rozsahu poistenia"])},
    "docs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tlačivá na stiahnutie"])},
    "required_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povinné informácie"])}
  },
  "cookies": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Používame súbory cookie."])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naša webová stránka používa súbory cookie. Tie sa môžu uložiť len po vašom súhlase."])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["súhlasím"])}
  }
}