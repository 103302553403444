<template>
  <div class=""></div>

  <div class="footer-w">
    <div class="footer wf-section">
      <div class="container">
        <div class="col-12 mb-m">
          <a href="/conditions" class="btn w-inline-block" v-if="$route.name === 'home'">
            <div class="mr">Mám záujem</div>
            <div class="ico ico-r"></div>
          </a>
        </div>
      </div>
      <div class="container">
        <div class="f-l">
          <div class="f-co">
            <a href="/files" class="fl w-inline-block">
              <div>Informácie o rozsahu poistenia</div>
            </a>
<!--            <a href="#" class="fl w-inline-block">-->
<!--              <div>Ochrana osobných údajov</div>-->
<!--            </a>-->
          </div>
          <div class="f-co">
            <a href="#" class="fl w-inline-block">
              <div class="ico mr"></div>
              <div>info@golfinsurance.sk<br></div>
            </a>
            <a href="#" class="fl w-inline-block">
              <div class="ico mr"></div>
              <div>0905 128 526</div>
            </a>
          </div>
        </div>
        <div class="f-r"><img src="images/logo_vw.svg" loading="lazy" alt="" class="f-logo"></div>
      </div>
    </div>
    <div class="container">
      <div class="coh"><img src="images/bh.png" loading="lazy" alt="" class="ft-l"><img src="images/colonnade.png" loading="lazy" alt="" class="ft-l"></div>
      <div class="copy">
        <div>© 2023 Golf Insurance. <br>Všetky práva vyhradené.</div>
      </div>
    </div>
  </div>
  <!-- cookie warning toast -->
  <div :class="{ 'toast':true, 'show': show,  'bg-light w-40 mw-40': true, 'cookie-popup': true }">
    <div  role="alert" data-autohide="false">
      <div class="toast-header">

        <strong class="me-auto"></strong>
        <small></small>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close" @click="save(0)"></button>
      </div>
      <div class="toast-body p-4 d-flex flex-column">
        <h4>{{ $t("cookies.title") }}</h4>
        <p>
          {{ $t("cookies.subtitle") }}
        </p>
        <div class="ml-auto">
<!--          <button type="button" class="btn btn-outline-light mr-3" id="btnDeny">-->
<!--            Deny-->
<!--          </button>-->
          <button type="button" class="btn btn-primary" @click="save(1)">
            {{ $t("cookies.button") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import CookieLaw from 'vue-cookie-law'
export default {
  name: "FooterPart",
  components: {

  },
  data: function (){
    return { show: false}
  },
  props: {
    product: String
  },
  methods: {
    save(val){
      localStorage.setItem('cookies', val);
      this.show = false;
    }
  },
  mounted() {
     this.show = !localStorage.getItem('cookies');
  }
}
</script>
<style>
.cookie-popup{
  display: none;
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: white;
  z-index: 10;
  padding: 15px;
  border: 1px solid #00b14e;

}
.cookie-popup.show{
  display: block;
}

</style>

