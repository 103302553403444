<template>
  <div class="w">
  <HeaderPart></HeaderPart>
<!--  <nav>-->
<!--    <router-link to="/">Home</router-link> |-->
<!--    <router-link to="/about">About</router-link>-->
<!--  </nav>-->

  <router-view/>

    <FooterPart></FooterPart>
  </div>
</template>

<!--<style lang="scss">-->
<!--// Import custom SASS variable overrides, or alternatively-->
<!--// define your variable overrides here instead-->
<!--$blue:    #0d6efd;-->
<!--$indigo:  #6610f2;-->
<!--$purple:  #6f42c1;-->
<!--$pink:    #d63384;-->
<!--$red:     #dc3545;-->
<!--$orange:  #fd7e14;-->
<!--$yellow:  #ffc107;-->
<!--$green:   #198754;-->
<!--$teal:    #20c997;-->
<!--$cyan:    #0dcaf0;-->

<!--// Import Bootstrap and BootstrapVue source SCSS files-->
<!--@import '~bootstrap/scss/bootstrap.scss';-->
<!--</style>-->

<style>





</style>

<script>
import HeaderPart from "@/components/parts/HeaderPart";
import FooterPart from "@/components/parts/FooterPart";
export default {
  components: {FooterPart, HeaderPart}
}
</script>