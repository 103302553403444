export default {
  "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Golfové pojištění"])},
  "off_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do nové golfové sezóny pro Vás připravujeme vylepšenou verzi pojištění.<br>V případě Vašeho zájmu nám zašlete Váš kontakt na <a href='mailto:info", "@", "golfinsurance.cz'>info", "@", "golfinsurance.cz</a><br>– budeme Vás informovat jakmile bude k dispozici."])},
  "contact": {
    "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+421 905 128 526"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["info", "@", "golfinsurance.cz"])}
  },
  "home": {
    "coverage_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oslavte Vaši výhru s naším golfovým pojištěním"])},
    "what_is_covered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rozsah pojištění"])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sjednat online"])},
    "price_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["roční pojistné"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2.475 Kč"])},
    "faq_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["otázky a odpovědi"])}
  },
  "test": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mám zájem o následující rozsah pojištění"])},
    "invalid": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pojištění nesplňuje Vaše požadavky"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["= prosím kontaktujte naše Klientské centrum"])}
    }
  },
  "people": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahlásenie spolucestujúcich osôb"])}
  },
  "application": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["údaje žádosti"])}
  },
  "order": {
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odeslat"])},
    "person": {
      "name": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jméno"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jan"])}
      },
      "surname": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["příjmení"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novák"])}
      },
      "birthday_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["datum narození"])},
      "email": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-mail"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["priklad", "@", "gmail.com"])}
      },
      "tel": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["telefon"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+420 000 000 000"])}
      },
      "trip_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trvání cesty od"])},
      "trip_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trvání cesty do"])}
    },
    "addPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ přidat další osobu"])},
    "address": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adresa trvalého pobytu"])},
      "street": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ulice"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Štefánikova"])}
      },
      "street_number": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["číslo domu"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["201"])}
      },
      "town": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["město"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Praha"])}
      },
      "zip": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PSČ"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["941 02"])}
      }
    },
    "national_id": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rodné číslo"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["napr. 2206109334"])}
    },
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["státní příslušnost"])},
    "id_card": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["číslo občanského průkazu"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S000002"])}
    },
    "action_code": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KOD akce"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "insurance_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["začátek platnosti pojištění"])},
    "discount_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slevový kód"])},
    "consent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "datepicker": {
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zvolit"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zrušit"])}
  },
  "navigation": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zpět"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pokračovat"])}
  },
  "gdpr": {
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na vámi zadanou mailovou adresu Vám zasíláme potvrzení o uzavření pojištění, pojistné podmínky a veškeré informační povinnosti a prosíme Vás o jejich prostudování. Úhradou pojistného zároveň prohlašujete, že jste byli úplně, srozumitelně a v dostatečném časovém předstihu seznámeni s jejich zněním a byla Vám vysvětlena všechna ustanovení v nich obsažená.\n"])},
    "communication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhlasím s elektronickou komunikací, informace související s poskytováním finančních služeb mi budou zaslány na mailovou adresu, kterou jsem zadal. Potvrzuji, že k této schránce elektronické pošty mám pravidelný přístup."])}
  },
  "header": {
    
  },
  "validation": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pole je povinné"])},
    "national_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rodné číslo není validní nebo věk je vyšší než 80 let"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pole musí obsahovat 5 číslic"])},
    "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonní číslo zadávejte ve formátu: +420 000 000 000"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte korektní email"])}
  },
  "payment": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platba"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platit online kartou"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Děkujeme za provedení platby"])},
    "already_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objednávka již byla zaplacena"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platba selhala"])},
    "ordered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Děkujeme za vytvoření objednávky. Platbu můžete provést kartou nebo převodem podle pokynů na mailu."])}
  },
  "files": {
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informace o pojištení"])},
    "law": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povinné informace"])},
    "reporting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hlášení škody"])}
  },
  "footer": {
    "all_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informace o rozsahu pojištění"])},
    "docs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formuláře"])},
    "required_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povinné informace"])}
  },
  "cookies": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Používáme cookies."])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Náš web používá cookies. Ty mohou být uloženy až po Vašem souhlasu."])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["souhlasím"])}
  }
}